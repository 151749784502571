import global from "../global";

// Navbar Mixin
export default {
    data() {
        return {
            warning_msg:"",
        };
    },
    methods: {
        getHierarchyListView(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + "/organization/companies/hierarchy", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch (error) {
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        switchCompany(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/auth/company/switch`, params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch (error) {
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
    },
};
