<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default overflow-visible modal-cover custom-lgflexible-width"
        :name="modal_name"
        :delay="100"
        height="auto"
        :width="800"
        :adaptive="true" 
        :scrollable="true">
        <div class="v-modal-content" style="background-color: #ffffff !important;border-radius: 10px !important;min-height: 450px !important">
            <div class="v-modal-header justify-content-center">
                <div class="v-modal-dialog-title text-danger text-capitalize v-modal-title text-white mt-1" style="color:#F44336 !important;font-size:18px !important">Access Denied</div>
            </div>
            <div class="v-modal-body">
                <div class="d-flex align-items-center justify-content-center py-5">
                    <img src="https://cdn.digicollect.com/cdn/dc_expense_fe/static/images/parrot.svg" style="height: 15rem !important" />
                </div>
                <div class="text-center fw-500 my-4">
                    {{ error_msg == 'Permission Denied' || error_msg == 'Employee Onboarding is Pending Contact Admin/HR' ? 'You don\'t have the access for this product, Please contact Admin.' : 'Token has been expired, Please login again' }}
                </div>
                <div class="text-center py-5">
                    <button class="btn btn-secondary text-white btn-smm text-capitalize position-relative" @click="DeleteSessionToken" style="box-shadow: none;border-radius: 5px !important;">
                        {{ error_msg == 'Permission Denied' || error_msg == 'Employee Onboarding is Pending Contact Admin/HR' ? 'Ok' : 'login' }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
    </template>
    
    <script>
    import { HalfCircleSpinner } from 'epic-spinners'
    import navbarMixin from '../mixins/navbarMixin'
    export default{
        props: ["modal_name", "error_msg"],
        mixins: [navbarMixin],
        data(){
            return{
            }
        },
        components: {
            HalfCircleSpinner
        },
        methods: {
            DeleteSessionToken(){
                this.$emit('DeleteSessionToken')
            }
        },
        mounted(){
    
        },
        computed: {
            loggedInUser(){
                return this.$store.state.loggedInUser
            }
        }
    }
    </script>
    
    <style scoped>
    .new-modal-default .v-modal-header{
        background: none !important;
        border-bottom: 1px solid #cdd2da;
        padding: 10px !important
    }
    .v-modal-content{
        background: rgba(0, 0, 0, 0.16) !important;
    }
    .new-modal-default .v-modal-dialog-title.v-modal-title{
        color: #00448b !important;
    }
    .text-capitalize{
        text-transform: capitalize !important;
    }
    .multiselect__placeholder{
        font-weight: 600 !important;
    }
    .w-150px{
        width: 150px !important
    }
    .btn-secondary:hover{
        background: #005dae;
    }
    .mx-auto{
        margin: 20px auto !important;
        display: block;
    }
    .illus-space{
        color: #4e5764 !important
    }
    .btn-secondary-outline{
        border-radius: 5px !important;
        border: solid 1px #00448b !important;
        color: #00448b !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        background-color: transparent;
    }
    .info-box{
        border: 1px solid #00448b;
        border-radius: 5px;
        padding: 10px;
        position: relative
    }
    .text-grey{
        color: #4e5764 !important;
    }
    .justify-content-evenly{
        justify-content: space-evenly;
    }
    .poscheckbox{
        position: relative;
        top: 0px;
        left: 0px
    }
    .diginewhelp-multiselect {
        font-size:14px;
        font-weight: 600;
        color: #7B7F83 !important;
    }
    .initialize-loader{
        z-index: 9998;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.20);
        opacity: 1;
    }
    .loader-container{
        width: 140px;
        height: 140px;
        background: transparent !important;
        border-radius: 5px;
        z-index: 99999;
        margin: auto;
        margin-top: 250px;
    }
    .fs-14{
        font-size: 14px !important
    }
    .btn-danger-outline{
        background-color: #fff;
        border: 1px solid #ed3e09;
        color: #ed3e09;
        border-radius: 5px;
    }
    .btn-danger-outline:disabled{
        border: none;
    }
    .btn-danger-outline:hover:disabled{
        background: #fff;
    }
    .btn-danger-outline:disabled{
        border: none;
    }
    .min-height-200{
        min-height: 200px !important
    }
    .close-icon-bg{
        background: #fff;
        position: absolute;
        right: 20px;
        top: 7px;
        z-index: 999;
        height: 22px;
    }
    .d-grid{
        display: grid;
        grid-template-columns: 40% 20% 15% 25%;
    }
    
    .position-relative{
        position: relative;
    }
    .w-10px{
        width: 10px;
    }
    .position-relative{
        position: relative;
    }
    @media screen and (max-width: 768px) {
        .d-grid{
            display: grid;
            grid-template-columns: 100%;
        }
    }
    .cursor-notallowed{
        background-color: #e8e8e8 !important;
    }
    .taxation-rotate {
        transform: rotate(180deg);
        transition: all 0.3s;
    }
    .active-taxation-rotate {
        transform: unset;
    }
    .btn-danger-outline{
        background-color: #fff;
        border: 1px solid #ed3e09;
        color: #ed3e09;
        border-radius: 5px;
    }
    .btn-success{
        background-color: #058b62;
    }
    .view-tax{
        background-color: #00448b;
        color: #fff;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 200px;
        border-radius: 6px;
        font-size: 12px !important;
    }
    .copy-data{
        background-color: #058b62;
        color: #fff;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 275px;
        border-radius: 6px;
        font-size: 12px !important;
    }
    .new-tax{
        background-color: #058b62;
        color: #fff;
        font-weight: 500;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 205px;
        border-radius: 6px;
        font-size: 14px !important;
    }
    .taxation-box{
        width: 100%;
        height: 100%;
        padding: 15.1px 14px 17.9px 14px;
        object-fit: contain;
        box-shadow: 0 0 3px 0 #005cab;
        background-color: #fff;
    }
    .federal-tax {
      width: 100%;
      height: 4100%;
      margin: 13.4px 9.2px 18px 0;
      padding: 9.2px 9px 15px 24px;
      object-fit: contain;
      border-radius: 8px;
      box-shadow: 0 1.5px 12.5px 0 rgba(59, 77, 92, 0.16);
      background-color: #fff;
    }
    .top-header{
        color: #4d4f5c;
        font-size: 12px;
        font-weight: 500;
    }
    .btn-success{
        color: #fff !important;
        text-transform: none !important;
        height: 34px !important;
    }
    .icon-plus-circle-outline.text-white{
        color: #fff !important
    }
    .position-absolute-left0px{
        position: absolute;
        left: 0px;
    }
    .min-width-max-content{
        min-width: max-content;
    }
    .overflow-responsive{
        overflow: visible !important;
    }
    .top-13px{
        top: 13px !important
    }
    .top-35px{
        top: 35px !important
    }
    .input-left-currency-type {
        padding: 7px 12px;
        background-color: rgb(241, 243, 244) !important;
        border-top: 0.5px solid #c6c7c9;
        border-left: 0.5px solid #c6c7c9;
        border-bottom: 0.5px solid #c6c7c9;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .min-width-150px{
        min-width: 150px !important
    }
    .input-field{
        height: auto !important
    }
    .digi-table-loader{
        position: absolute;
    }
    @media screen and (max-width: 920px) {
        .overflow-responsive{
            overflow: auto !important;
        }
    }
    </style>