import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // clockInTime: {},
    loggedInUserCompanyId: '',
    loggedInUser: {},
    organizationDetails: {},
    companyDetails: {},
    userAccounts: [],
    activeToken: "",
    activeRefresh: false,
    account: null,
    session_id: null,
    user_id: null,
    selectedCompany: null,
    fcm_details: {
      fcm_token: "",
      fcm_server_key: ""
    }
  },
  // Getters for All Permissions
  getters: {
    // clockInTime: state => state.clockInTime,
    loggedInUser: state => state.loggedInUser,
    organizationDetails: state => state.organizationDetails,
    companyDetails: state => state.companyDetails,
    userAccounts: state => state.userAccounts,
    activeToken: state => state.activeToken,
    activeRefresh: state => state.activeRefresh,
    account: state => state.account,
    selectedCompany: state => state.selectedCompany,
    loggedInUserCompanyId: state => state.loggedInUserCompanyId,
    fcmDetails: state => state.fcm_details,
    session_id: state => state.session_id,
    user_id: state => state.user_id,
  },
  mutations: {
    // setClockTime(state, details) {
    //   state.clockInTime = details
    // },
    setCompanyDetails(state, details) {
      state.companyDetails = details
    },
    setOrganizationDetails(state, details){
      state.organizationDetails = details
    },
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    setUserAccounts(state, accounts) {
      state.userAccounts = accounts;
    },
    setToken(state, token) {
      state.activeToken = token;
    },
    setRefresh(state, refresh) {
      state.activeRefresh = refresh;
    },
    setSelectedCompany(state, company_data) {
      state.selectedCompany = company_data;
    },
    setloggedInUserCompanyId(state, comp_id) {
      state.loggedInUserCompanyId = comp_id;
    },
    setSessionId(state, session_id) {
      state.session_id = session_id
    },
    setFCMDetails(state, fcm_details) {
      state.fcm_details = fcm_details
    },
    setUserId(state, user_id) {
      state.user_id = user_id
    }
  },
});
